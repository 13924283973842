<template>
  <board-list-widget :org-ref="orgRef" entity-type="deal" title="deals">
    <template v-slot:card="{ board, show }">
      <VWidget enable-footer-actions css-class="hover-border">
        <template v-slot:title>{{ board.display }}</template>
        <template slot="footer-actions">
          <v-btn small icon @click="show(board.id)">
            <v-icon small v-text="$vuetify.icons.values.view" />
          </v-btn>
        </template>
      </VWidget>
    </template>
  </board-list-widget>
</template>

<script>
import { BoardListWidget } from '@argon/evo/boards/widgets'
import { VWidget } from '@argon/app/components'
import { NAMESPACE as ORG_NAMESPACE } from '@argon/iam/organizations/store'
import { mapActions } from 'vuex'

export default {
  name: 'DealBoardList',
  components: {
    VWidget,
    BoardListWidget
  },
  props: {
    orgRef: String
  }
}
</script>
